import React, { useEffect } from 'react';
import Loader from 'components/Loader/Loader';
import Log from 'helper/sentry';
import { parseJwt } from 'helper/user';

type AppleCallbackProps = {
  response: any;
  onResponse: (res: any) => void;
  onError: (err: any) => void;
};

const AppleCallback: React.FC<AppleCallbackProps> = ({
  response,
  onResponse,
  onError,
}) => {

  useEffect(() => {
    if (response === 'ignore') return;

    if (!response) {
      return onError('There was an error authenticating you with Sign In with Apple. Please try again later.');
    }

    try {
      handleAppleResponse(response.id_token);      
    } catch (error) {
      onError('There was an application error. Please try again.');
    }
  }, [response]);

  const handleAppleResponse = (idToken: string) => {
    const body = parseJwt(idToken);
    const user = body?.user ? JSON.parse(body.user) : null;
    Log.debug('Apple auth responded', 'sign-in-with-apple', { response, body, user })

    if (!body?.email || !idToken) {
      return onError('There was an error authenticating you with Sign In with Apple. Please try again later.');
    }

    onResponse({
      form: user ? 'sign-up' : 'sign-in',
      input: {
        firstName: user?.name.firstName,
        lastName: user?.name.lastName,
        email: body?.email,
        authToken: idToken,
        authType: "APPLE"
      }
    });
  };

  return <Loader className='loader' />;
};

export default AppleCallback;