import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Form,
  Input,
  Button,
  LegalText,
  SwitchForm,
} from './Authentication.style';
import { validateEmail } from 'helper/user';
import { LOGIN_ROUTE } from 'constants/navigation';

type SignupProps = {
  onResponse: (res: any) => void;
  onError: (err: any) => void;
};

const Signup: React.FC<SignupProps> = ({
  onResponse,
  onError
}) => {
  const router = useRouter();
  const referralCode = router?.query?.referral?.toString();
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (!validateEmail(emailInput)) {
      return onError('Please enter a valid email and try again.');
    }

    if (firstNameInput.length < 1 || lastNameInput.length < 1 || emailInput.length < 1) {
      return onError('Please fill out all fields and try again.');
    }

    if (passwordInput.length < 6 || confirmPasswordInput.length < 6) {
      return onError('Please make sure your password is at least 6 characters long.');
    }

    if (passwordInput !== confirmPasswordInput) {
      return onError('Your password and confirm password do not match.');
    }

    onResponse({
      firstName: firstNameInput,
      lastName: lastNameInput,
      email: emailInput,
      authToken: passwordInput,
      authType: 'DEVO'
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type='text'
        placeholder={'First Name'}
        value={firstNameInput}
        onChange={(e) => setFirstNameInput(e.target.value)}
        required
        className='half left'
      />
      <Input
        type='text'
        placeholder={'Last Name'}
        value={lastNameInput}
        onChange={(e) => setLastNameInput(e.target.value)}
        required
        className='half right'
      />
      <Input
        type='text'
        placeholder={'Email Address'}
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
        required
      />
      <Input
        type='password'
        placeholder={'Password'}
        value={passwordInput}
        onChange={(e) => setPasswordInput(e.target.value)}
        required
      />
      <Input
        type='password'
        placeholder={'Confirm Password'}
        value={confirmPasswordInput}
        onChange={(e) => setConfirmPasswordInput(e.target.value)}
        required
      />

      <LegalText style={{ padding: '20px 0 30px' }}>
        {"By creating an account you agree to Devo's "}
        <Link href='/legal/terms'>
          <a target='_blank'>Terms & Conditions</a>
        </Link>
        {' and '}
        <Link href='/legal/privacy'>
          <a target='_blank'>Privacy Policy</a>
        </Link>.
      </LegalText>

      <Button
        fullwidth
        type='submit'
        title='Continue'
        style={{ fontWeight: 600 }}
      />

      <SwitchForm>
        {'Already have an account? '}
        <Link href={`${LOGIN_ROUTE}${router?.query?.redirect ? `?redirect=${router.query.redirect}` : ''}`}>
          Login
        </Link>
      </SwitchForm>
    </Form>
  );
};

export default Signup;