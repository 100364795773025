import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  Page,
  Container,
  Divider,
} from './Authentication.style';
import LoginProviders from './LoginProviders';
import AppleCallback from './AppleCallback';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import Loader from 'components/Loader/Loader';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { SIGN_IN, SIGN_UP, SIGN_UP_REFERRAL } from 'graphql/query/auth.query';
import { HOME_ROUTE, LOGIN_ROUTE } from 'constants/navigation';
import { AuthContext } from 'contexts/auth/auth.context';
import { getCurrentUserUuid, setLoginTokens } from 'helper/user';

import Log from 'helper/sentry';
import showOkPopup from 'containers/Popup/OKPopup';
import parseError from 'helper/error';
import { GET_PUBLIC_USER, GET_REFERRAL_CODE } from 'graphql/query/referral.query';
import { CookieKeys, getCookie, removeCookie } from 'components/helpers/session';
import { logAnalytics } from 'helper/gtm/helper';
import { newLogin, newSignup } from 'helper/gtm/auth-events';

type AuthenticationProps = {
  signIn?: boolean;
  signUp?: boolean;
  forgotPassword?: boolean;
  appleCallback?: any;
};

const Authentication: React.FC<AuthenticationProps> = ({
  signIn = false,
  signUp = false,
  forgotPassword = false,
  appleCallback,
}) => {
  const router = useRouter();
  const [referralCode, setReferralCode] = useState(router?.query?.referral?.toString());
  const [redirect, setRedirect] = useState(router?.query?.redirect?.toString());
  const { authState, authDispatch } = useContext<any>(AuthContext);

  const [facebookLoading, setFacebookLoading] = useState(router?.query?.state === 'facebookdirect');
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const loading = facebookLoading || googleLoading || appleLoading;

  useEffect(() => {
    const referralCodeCookie = getCookie(CookieKeys.pendingReferral);
    const redirectCookie = getCookie(CookieKeys.pendingRedirect);
    Log.debug('Fetched cookies', 'auth', { referralCodeCookie, referralCode, redirectCookie, redirect });
    if (!referralCode) setReferralCode(referralCodeCookie);
    if (!redirect) setRedirect(redirectCookie);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (forgotPassword) return;
    var url = `${router.pathname}?`;

    if (router.query?.state === 'facebookdirect') {
      setFacebookLoading(true);
      url = `${url}state=${router.query.state}&code=${router.query?.code}`;
    }
    if (redirect) url = `${url}redirect=${redirect}&`;
    if (referralCode) url = `${url}referral=${referralCode}&`;

    if (url.slice(-1) === '&') url = url.slice(0, -1);
    if (url.slice(-1) === '?') url = url.slice(0, -1);

    Log.debug('Constructing auth URL...', 'auth', { referralCode, redirect, url });
    replaceAfter(500, url);
  }, [referralCode, redirect]);

  const [replaceTimeout, setReplaceTimeout] = useState(null);
  const replaceAfter = (timeout, url) => {
    if (replaceTimeout) clearTimeout(replaceTimeout);
    setReplaceTimeout(setTimeout(() => {
      router.replace(url);
    }, timeout));
  };

  // API

  const [triggerSignUpReferral] = useMutation(SIGN_UP_REFERRAL);
  const [triggerSignUp] = useMutation(SIGN_UP);
  const [triggerSignIn] = useMutation(SIGN_IN);

  const attemptRegister = (input: any) => {
    startRegister(input).then(attemptSignIn);
  };

  const attemptSignIn = (input: any) => {
    startSignIn(input).then(completion);
  };

  const startRegister = async (input: any) => {
    const body = {
      firstName: input?.firstName,
      lastName: input?.lastName,
      email: input?.email,
      authType: input?.authType,
      password: input?.authType === 'DEVO' ? input?.authToken : null
    };

    Log.debug('Start register...', 'auth', { input, referralCode, body });
    const auth = { email: input?.email, authToken: input?.authToken, authType: input?.authType };
    const selector = referralCode ? 'triggerSignUpReferral' : 'triggerSignUp';
    try {
      const signUpMutation = referralCode ? triggerSignUpReferral : triggerSignUp;
      const response = await signUpMutation({ 
        variables: {
          referral: referralCode,
          input: body
        }
      });
      const { data, errors } = response;

      Log.debug(`${selector} response`, 'auth', response);

      if (data?.auth) {
        removeCookie(CookieKeys.pendingReferral);
        removeCookie(CookieKeys.pendingRedirect);
        newSignup(input?.authType);
        return auth;
      } else {
        throw errors || 'Could not read data from sign up response';
      }
    } catch (error) {
      Log.debug(`${selector} caught error`, 'auth', error);
      return auth;
    }
  };

  const startSignIn = async (input: any) => {
    Log.debug('Start sign in...', 'auth', input);
    try {
      if (!input?.email || !input?.authToken || !input?.authType) {
        throw 'Invalid auth input';
      };

      const response = await triggerSignIn({
        variables: { input }
      });
      const { data, errors } = response;

      if (data?.auth) {
        removeCookie(CookieKeys.pendingReferral);
        removeCookie(CookieKeys.pendingRedirect);
        newLogin(input?.authType);
        return data?.auth;
      } else {
        throw errors || 'Could not read data from sign in response';
      }
    } catch (error) {
      Log.warning('triggerSignIn caught error', 'auth', error);
      handleAPIError();
    }
  };

  const handleAPIError = () => {
    setLoading(false);
    const errorCallback = appleCallback ? handleAppleError : handleError;
    errorCallback('There was an error authenticating you with Devo. Please try again later.', null);
  };

  const completion = (res: any) => {
    setLoading(false);
    if (res?.authToken && res?.refreshToken) {
      Log.debug('Successfully logged in!', 'auth', null);
      setLoginTokens(res.authToken, res.refreshToken);
      authDispatch({ type: 'AUTHENTICATED' });
    } else {
      Log.warning('Could not parse login response', 'auth', res);
    }
  };

  const userUuid = getCurrentUserUuid();
  useEffect(() => {
    if (!userUuid) return;
    Log.debug('Redirecting...', 'auth', { redirect });
    redirectAfter(500, redirect || HOME_ROUTE);
  }, [userUuid]);

  const [redirectTimeout, setRedirectTimeout] = useState(null);
  const redirectAfter = (timeout, href) => {
    if (redirectTimeout) clearTimeout(redirectTimeout);
    setRedirectTimeout(setTimeout(() => {
      router.push(href);
    }, timeout));
  };

  // LOGIN PROVIDERS

  const handleLoginProviderResponse = (form: 'signin' | 'signup', res: any) => {
    Log.debug('handleLoginProviderResponse', 'auth', { form, res });
    if (!res?.firstName || !res?.lastName) {
      attemptSignIn(res);
    } else {
      attemptRegister(res);
    }
  };

  const handleLoginProviderError = (err: any) => {
    handleError(err, null);
  };

  // APPLE

  const handleAppleCallbackResponse = (res: any) => {
    const { input, form } = res;
    handleLoginProviderResponse(form, input);
  };

  const handleAppleError = (err: any) => {
    const redirect = () => router.replace(LOGIN_ROUTE);
    handleError(err, appleCallback ? redirect : null);
  };

  // HELPERS

  const setLoading = (loading: boolean) => {
    setFacebookLoading(loading);
    setGoogleLoading(loading);
    setAppleLoading(loading);
  }

  const handleError = (err: any, onCompletion: () => void) => {
    if (loading) return setLoading(false);
    setLoading(false);

    Log.debug('handleError', 'auth', { err });
    logAnalytics('login_failed', {
      status: err?.networkError?.statusCode,
      message: parseError(err) 
    });
    showOkPopup({
      title: 'Something went wrong!',
      description: parseError(err),
      cancelText: 'OK',
      didClickCancel: onCompletion,
      didClickClose: onCompletion
    });
  };

  // REFERRAL

  const { data: referralData } = useQuery(GET_REFERRAL_CODE, {
    skip: !referralCode,
    variables: { code: referralCode },
    onCompleted: res => Log.debug('GET_REFERRAL_CODE onCompleted', 'referral', { ...res, input: referralCode }),
  });
  const referral = referralData?.referral;

  const { data: publicUserData } = useQuery(GET_PUBLIC_USER, {
    skip: !referral?.sourceUuid,
    variables: { sourceUuid: referral?.sourceUuid },
    onCompleted: res => Log.debug('GET_PUBLIC_USER onCompleted', 'referral', res),
  });
  const publicUser = publicUserData?.publicUser;

  // RENDER

  if (userUuid) {
    return (
      <Page>
        <Container>
          <Loader className='loader' />
        </Container>
      </Page>
    );
  }

  if (appleCallback) {
    return (
      <>
        <Page>
          <Container>
            <AppleCallback
              response={appleCallback}
              onResponse={handleAppleCallbackResponse}
              onError={handleAppleError}
            />
          </Container>
        </Page>
      </>
    );
  }

  if (forgotPassword) {
    return (
      <>
        <Page>
          <Container>
            <h1>Forgot Password</h1>
            <ForgotPassword 
              onError={handleLoginProviderError}
            />
          </Container>
        </Page>
      </>
    );
  }

  if (signIn) {
    return (
      <>
        <Page>
          <Container>
            <h1>Login</h1>
            <LoginProviders
              form='signin'
              onResponse={res => handleLoginProviderResponse('signin', res)}
              onError={handleLoginProviderError}
              facebookLoading={facebookLoading}
              setFacebookLoading={setFacebookLoading}
              googleLoading={googleLoading}
              setGoogleLoading={setGoogleLoading}
              appleLoading={appleLoading}
              setAppleLoading={setAppleLoading}
            />
            <Divider>
              <span>or, continue with email</span>
            </Divider>
            <Login 
              onResponse={res => handleLoginProviderResponse('signin', res)}
              onError={handleLoginProviderError}
            />
          </Container>
        </Page>
      </>
    )
  }

  if (signUp) {
    return (
      <Page>
        <Container>
          {referral && publicUser ? (
            <h1 style={{ fontSize: 20 }}>Sign up to accept {publicUser.firstName}'s referral and get £{referral.rewardAmount?.toLocaleString() || 0} off your first order!</h1>
          ) : (
            <h1>Sign Up</h1>
          )}
          <LoginProviders
            form='signup'
            onResponse={res => handleLoginProviderResponse('signup', res)}
            onError={handleLoginProviderError}
            facebookLoading={facebookLoading}
            setFacebookLoading={setFacebookLoading}
            googleLoading={googleLoading}
            setGoogleLoading={setGoogleLoading}
            appleLoading={appleLoading}
            setAppleLoading={setAppleLoading}
          />
          <Divider>
            <span>or, continue with email</span>
          </Divider>
          <Signup 
            onResponse={res => handleLoginProviderResponse('signup', res)}
            onError={handleLoginProviderError}
          />
        </Container>
      </Page>
    )
  }

  return <></>;
};

export default Authentication;