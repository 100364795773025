import gql from 'graphql-tag';

export const FORGOT_PASSWORD = gql`
  mutation ($email: string) {
    forgotPassword(email: $email, input: {})
    @rest(method: "POST", path: "/user/forgotPassword?email={args.email}", endpoint: "as") {
      NoResponse
    }
  }
`;
