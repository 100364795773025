import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  ForgotPassword,
  Form,
  Input,
  Button,
  SwitchForm,
} from './Authentication.style';
import { FORGOT_PASSWORD_ROUTE, REGISTER_ROUTE } from 'constants/navigation';
import { validateEmail } from 'helper/user';
import { logAnalytics } from 'helper/gtm/helper';

type LoginProps = {
  onResponse: (res: any) => void;
  onError: (err: any) => void;
};

const Login: React.FC<LoginProps> = ({
  onResponse,
  onError,
}) => {
  const router = useRouter();
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (!validateEmail(emailInput)) {
      return onError('Please enter a valid email and try again.');
    }

    if (passwordInput.length < 1) {
      return onError('Please enter a password and try again.');
    }

    onResponse({
      email: emailInput,
      authToken: passwordInput,
      authType: 'DEVO'
    });
  };

  const forgotPasswordHref = (): string => {
    if (validateEmail(emailInput)) {
      return `${FORGOT_PASSWORD_ROUTE}?email=${emailInput}`;
    } else {
      return `${FORGOT_PASSWORD_ROUTE}`;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type='text'
        placeholder={'Email Address'}
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
        required
      />
      <Input
        type='password'
        placeholder={'Password'}
        value={passwordInput}
        onChange={(e) => setPasswordInput(e.target.value)}
        required
      />
      
      <ForgotPassword>
        <a href={forgotPasswordHref()} onClick={() => logAnalytics('forgot_password')}>
          Forgot Password?
        </a>
      </ForgotPassword>

      <Button
        fullwidth
        type='submit'
        title='Continue'
        style={{ fontWeight: 600 }}
      />

      <SwitchForm>
        {"Don't have an account? "}
        <Link href={`${REGISTER_ROUTE}${router?.query?.redirect ? `?redirect=${router.query.redirect}` : ''}`}>
          Sign Up
        </Link>
      </SwitchForm>
    </Form>
  );
};

export default Login;