import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Buttons from 'components/Button/Button';

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const Container = styled.div`
  width: min(600px, 50%);
  min-height: 100vh;
  margin: 0 auto;
  padding: 125px 0;

  & > h1 {
    font-size: 25px;
    margin-bottom: 25px;
    text-align: center;
  }

  .loader {
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 80px 0;
    width: 90%;
  }
`;

export const Button = styled(Buttons)`
  &.google, &.facebook, &.apple {
    span.btn-text {
      font-weight: 600;
      font-size: 16px;
    }
  }

  &.google {
    background-color: #4285f4;
    margin-bottom: 10px;
  }

  &.facebook {
    background-color: #4267b2;
    margin-bottom: 10px;
  }

  &.apple {
    background-color: #000;

    img {
      height: 20px;
      width: 20px;
      filter: invert(1);
      object-fit: contain;
    }
  }
`;

export const Divider = styled.div`
  padding: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  span {
    font-size: 16px;
    color: #585858;
    line-height: 1;
    background-color: #fff;
    z-index: 1;
    position: relative;
    padding: 0 10px;
  }

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${themeGet('colors.borderColor', '#E6E6E6')};
    position: absolute;
    top: 50%;
  }
`;

export const Form = styled.form`
  & > p {
    color: #77798c;
  }

  @media (min-width: 768px) {
    .half {
      width: calc(50% - 5px);
    }
  
    .left {
      margin-right: 10px;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: #F7F7F7;
  border: 0.5px solid #E6E6E6;
  font-size: 16px;
  color: #0D1136;
  line-height: 19px;
  padding: 0 18px;
  box-sizing: border-box;
  transition: border-color 0.25s ease;
  margin-bottom: 10px;

  &:hover, &:focus {
    outline: 0;
  }

  &:focus {
    border-color: #EE504F;
  }

  &::placeholder {
    color: #77798c;
    font-size: 16px;
  }

  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.disabled {
    .inner-wrap {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

export const ForgotPassword = styled.div`
  text-align: right;
  margin-bottom: 10px;

  a {
    display: inline-block;
    background-color: transparent;
    color: #585858;
    border: 0;
    outline: 0;
    box-shadow: none;
    padding: 15px;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
  }
`;

export const LegalText = styled.p`
  font-size: 13px;
  color: #77798c;
  margin: 0;
  text-align: center;
  width: 100%;

  a {
    font-weight: 800;
    color: #EE504F;
    text-decoration: underline;
  }
`;

export const SwitchForm = styled.p`
  font-size: 15px;
  color: #77798c;
  margin: 20px 0 0;
  text-align: center;
  width: 100%;

  a {
    font-weight: 800;
    color: #EE504F;
    text-decoration: underline;
  }
`;