import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Form,
  Input,
  Button,
  SwitchForm,
} from './Authentication.style';
import { validateEmail } from 'helper/user';
import { useMutation } from '@apollo/react-hooks';
import { FORGOT_PASSWORD } from 'graphql/mutation/password';
import Log from 'helper/sentry';
import { LOGIN_ROUTE } from 'constants/navigation';

type ForgotProps = {
  onError: (err: any) => void;
}

const Forgot: React.FC<ForgotProps> = ({
  onError,
}) => {
  const router = useRouter();

  const [success, setSuccess] = useState(false);
  const [emailInput, setEmailInput] = useState(router?.query?.email?.toString() || '');

  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateEmail(emailInput)) {
      return onError('Please enter a valid email and try again.');
    }

    Log.debug('Start forgot password...', 'auth', { emailInput });
    try {
      const response = await forgotPassword({
        context: { headers: { Accept: '' }},
        variables: { 
          email: emailInput 
        },
      });

      Log.debug('forgotPassword response', 'auth', response);
      const { errors } = response;

      if (errors) {
        throw errors;
      } else {
        setSuccess(true);
      }
    } catch (error) {
      Log.warning('forgotPassword caught error', 'auth', error);
      onError("We were unable to verify your account. Please try again later.");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {success ? (
        <p style={{ textAlign: 'center', margin: '40px 0' }}>Email sent! Please check your inbox for an email with instructions on how to reset your password.</p>
      ) : (
        <>
          <p>Please enter your email below and we'll send you a link to reset your password.</p>  
        
          <Input
            type='text'
            placeholder={'Email Address'}
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            required
            style={{ margin: '25px 0' }}
          />

          <Button
            fullwidth
            type='submit'
            title='Reset Password'
            style={{ fontWeight: 600 }}
          />
        </>
      )}

      <SwitchForm>
        {'Back to '}
        <Link href={LOGIN_ROUTE}>
          Login
        </Link>
      </SwitchForm>
    </Form>
  );
};

export default Forgot;