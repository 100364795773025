import { logAnalytics, push } from './helper';
import { getCurrentUserUuid } from 'helper/user';

export type AuthMethod = 'FACEBOOK' | 'GOOGLE' | 'APPLE' | 'EMAIL';

export const newSignup = (method: AuthMethod) => {
  logAnalytics('sign_up', { method });
};

export const newLogin = (method: AuthMethod) => {
  logAnalytics('login', { method, action: 'manual' });
};