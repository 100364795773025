import gql from 'graphql-tag';

export const SIGN_IN = gql`
  mutation signIn($input: any) {
    auth(input: $input) @rest(type: "AuthTokenModel", method: "POST", path: "/auth/login", endpoint: "as") {
      authToken
      refreshToken
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($input: any) {
    auth(input: $input) @rest(type: "UserModel", method: "POST", path: "/user/new", endpoint: "as") {
      uuid
      firstName
      lastName
      email
      phoneNumber
      joinedDate
      over18
    }
  }
`;

export const SIGN_UP_REFERRAL = gql`
  mutation signUp($input: any, $referral: string) {
    auth(input: $input, referral: $referral) @rest(type: "UserModel", method: "POST", path: "/user/new?referralCode={args.referral}", endpoint: "as") {
      uuid
      firstName
      lastName
      email
      phoneNumber
      joinedDate
      over18
    }
  }
`;
