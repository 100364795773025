import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  Button,
} from './Authentication.style';
import { GoogleLogin } from 'react-google-login';
// import FacebookAuth from 'react-facebook-auth';
import FacebookAuth from './FacebookAuth';

import Apple from 'image/general/apple.svg';
import { Facebook, Google } from 'components/AllSvgIcon';
import Log from 'helper/sentry';
import { separateName } from 'helper/user';
import { setCookie, CookieKeys } from 'components/helpers/session';

type LoginProvidersProps = {
  form: 'signin' | 'signup';
  facebookLoading: Boolean;
  setFacebookLoading: Function;
  googleLoading: Boolean;
  setGoogleLoading: Function;
  appleLoading: Boolean;
  setAppleLoading: Function;
  onResponse: (res: any) => void;
  onError: (err: any) => void;
};

const LoginProviders: React.FC<LoginProvidersProps> = ({
  form,
  facebookLoading,
  setFacebookLoading,
  googleLoading,
  setGoogleLoading,
  appleLoading,
  setAppleLoading,
  onResponse,
  onError,
}) => {
  const router = useRouter();
  const redirect = router?.query?.redirect?.toString();
  const referralCode = router?.query?.referral?.toString();
  const loading = facebookLoading || googleLoading || appleLoading;

  // FACEBOOK

  const handleFacebookClicked = (forward: () => void) => {
    if (loading) return;
    setFacebookLoading(true);

    try {
      forward();
    } catch (error) {
      Log.error('Could not load Facebook login!', form, error);
      onError('There was an error connecting to Facebook. Please check you are not using private browsing and try again later.');
    }
  };

  const onFacebookResponse = (response: any) => {
    setFacebookLoading(true);
    Log.debug('Facebook auth responded', form, response);

    if (typeof window === 'undefined') { 
      return setFacebookLoading(false);
    };

    if (!response?.email || !response?.accessToken || !response?.name) {
      if (response?.status !== 'unknown') {
        Log.warning('Facebook authentication failed', form, response);
        onError('There was an error authenticating you with Facebook. Please try again later.');
      }
      return setFacebookLoading(false);
    };

    const names = separateName(response.name);

    onResponse({
      firstName: names[0] || '',
      lastName: names[1] || '',
      email: response.email,
      authToken: response.accessToken,
      authType: 'FACEBOOK'
    });
  };

  const facebookRedirectUri = (): string => {
    const hostname = typeof window !== 'undefined' ? window.location.hostname : 'https://devo.co.uk';
    if (form === 'signup') {
      return `https://${hostname}/join`;
    } else {
      return `https://${hostname}/login`;
    }
  };

  // GOOGLE

  const handleGoogleClicked = (forward: () => void) => {
    if (loading) return;
    setGoogleLoading(true);
    forward();
  };

  const onGoogleResponse = (response: any) => {
    setGoogleLoading(true);
    Log.debug('Google auth responded', form, response);

    if (typeof window === 'undefined') { 
      return setGoogleLoading(false);
    };

    if (response?.error === 'popup_closed_by_user') return;

    if (response?.error === 'idpiframe_initialization_failed') {
      Log.error("There was an error connecting to Google, this domain probably isn't registered in the Google Console.", form, response);
      return setGoogleLoading(false);
    }

    if (!response?.profileObj?.givenName || !response?.profileObj?.familyName || !response?.profileObj?.email || !response?.tokenId) {
      Log.warning('Google authentication failed', form, response);
      onError('There was an error authenticating you with Google. Please try again later.');
      return setGoogleLoading(false);
    }

    onResponse({
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
      email: response.profileObj.email,
      authToken: response.tokenId,
      authType: 'GOOGLE',
    });
  };

  // APPLE

  const handleAppleClicked = (e: any) => {
    e.preventDefault();
    if (loading) return;

    if (referralCode) setCookie(CookieKeys.pendingReferral, referralCode);
    if (redirect) setCookie(CookieKeys.pendingRedirect, redirect);

    const params = {
      clientId: 'uk.co.devo.web-app',
      redirectURI: appleRedirectUri(),
      scope: 'name email',
    };

    // @ts-ignore
    const appleIDClient = window?.AppleID;

    if (appleIDClient) {
      setAppleLoading(true);
      appleIDClient.auth.init(params);
      appleIDClient.auth.signIn();
    } else {
      Log.error('AppleID client not found!', form, { appleIDClient });
      onError('Sign In with Apple is currently unavailable. Please try again later.');
    }
  };

  const appleRedirectUri = (): string => {
    const hostname = typeof window !== 'undefined' ? window.location.hostname : 'https://devo.co.uk';
    return `https://${hostname}/auth/apple`;
  };

  // RENDER

  return (
    <>
      <Head>
        <script type="text/javascript" src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" />
      </Head>
      <FacebookAuth
        appId="933304130105350"
        version="8.0"
        redirectUri={facebookRedirectUri()}
        callback={onFacebookResponse}
        disableRedirect={true}
        reAuthenticate
        component={renderProps => (
          <Button
            fullwidth
            isLoading={facebookLoading}
            title={'Continue with Facebook'}
            className='facebook'
            icon={<Facebook />}
            iconPosition='left'
            iconStyle={{ color: '#ffffff', marginRight: 5 }}
            intlButtonId='continueFacebookBtn'
            onClick={() => handleFacebookClicked(renderProps.onClick)}
            style={{ color: '#fff' }}
          />
        )}
      />

      <GoogleLogin
        clientId='977486417793-h0pnf3icpn5bfhh766584smucp9igm4d.apps.googleusercontent.com'
        render={renderProps => (
          <Button
            fullwidth
            isLoading={googleLoading}
            title={'Continue with Google'}
            className='google'
            icon={<Google />}
            iconPosition='left'
            iconStyle={{ color: '#ffffff', marginRight: 5 }}
            intlButtonId='continueGoogleBtn'
            onClick={() => handleGoogleClicked(renderProps.onClick)}
            disabled={renderProps.disabled}
            style={{ color: '#fff' }}
          />
        )}
        prompt='consent'
        onAutoLoadFinished={() => {}}
        onSuccess={onGoogleResponse}
        onFailure={onGoogleResponse}
        cookiePolicy={'single_host_origin'}
      />

      <Button
        fullwidth
        isLoading={appleLoading}
        title={'Continue with Apple'}
        className='apple'
        icon={<img src={Apple} />}
        iconPosition='left'
        iconStyle={{ marginRight: 5 }}
        intlButtonId='continueAppleBtn'
        onClick={handleAppleClicked}
        style={{ padding: 0 }}
      />
    </>
  );
};

export default LoginProviders;