// From: https://github.com/ionutmilica/react-facebook-auth
import React from 'react'
import Log from 'helper/sentry'

export function toQueryString(object) {
  return Object.keys(object)
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join('&');
};

export function isUsingMobile() {
  try {
    // @ts-ignore
    return !!((window.navigator && window.navigator.standalone) ||
      navigator.userAgent.match('CriOS') ||
      navigator.userAgent.match(/mobile/i)
    );
  } catch (ex) {
    return false;
  }
};

const fbClient = (): any => {
  // @ts-ignore
  return window.FB;
};

type FacebookAuthProps = {
  component: React.ReactNode;
  callback: Function;
  onFailure?: Function;
  appId: string;
  xfbml?: boolean;
  cookie?: boolean;
  isMobile?: boolean;
  redirectUri?: string;
  reAuthenticate?: boolean;
  reRequest?: boolean;
  disableRedirect?: boolean;
  scope?: string;
  returnScopes?: boolean;
  autoLoad?: boolean;
  fields?: string;
  version?: string;
  language?: string;
  customProps?: any;
};

class FacebookAuth extends React.Component<FacebookAuthProps> {

  static defaultProps = {
    redirectUri: typeof window !== 'undefined' ? window.location.href : '/',
    scope: 'public_profile,email',
    onFailure: undefined,
    returnScopes: false,
    xfbml: false,
    cookie: false,
    isMobile: isUsingMobile(),
    reAuthenticate: false,
    reRequest: false,
    fields: 'name,email,picture',
    version: '2.8',
    language: 'en_US',
    autoLoad: false,
    disableRedirect: false,
    customProps: {},
  };

  componentDidMount() {
    if (document.getElementById('facebook-jssdk')) {
      return;
    }

    this.setfbAsyncInit();
    this.loadSdkAsynchronously();

    let rootElem = document.getElementById('fb-root');
    if (!rootElem) {
      rootElem = document.createElement('div');
      rootElem.id = 'fb-root';
      document.body.appendChild(rootElem);
    }
  }

  setfbAsyncInit() {
    const { appId, xfbml, cookie, version, autoLoad } = this.props;
    // @ts-ignore
    window.fbAsyncInit = () => {
      fbClient().init({
        version: `v${version}`,
        appId,
        xfbml,
        cookie,
      });

      if (autoLoad || window.location.search.indexOf('facebookdirect') !== -1) {
        fbClient().getLoginStatus(this.checkLoginAfterRefresh);
        Log.debug('setfbAsyncInit getLoginStatus', 'facebook-auth', null);
      }
    };
  }

  loadSdkAsynchronously() {
    const language = this.props.language;
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      // @ts-ignore
      js.src = `https://connect.facebook.net/${language}/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  responseApi = authResponse => {
    Log.debug('responseApi', 'facebook-auth', { authResponse });
    fbClient().api(
      '/me',
      { locale: this.props.language, fields: this.props.fields },
      me => {
        this.props.callback({
          ...me,
          ...authResponse,
        });
      },
    );
  };

  checkLoginState = response => {
    Log.debug('checkLoginState', 'facebook-auth', { response });
    if (response.authResponse) {
      this.responseApi(response.authResponse);
      return;
    }
    if (this.props.onFailure) {
      this.props.onFailure({ status: response.status });
    } else {
      this.props.callback({ status: response.status });
    }
  };

  checkLoginAfterRefresh = response => {
    Log.debug('checkLoginAfterRefresh', 'facebook-auth', { response });
    if (response.status === 'unknown') {
      fbClient().login(
        loginResponse => this.checkLoginState(loginResponse),
        true,
      );
    } else {
      this.checkLoginState(response);
    }
  };

  click = e => {
    const {
      scope,
      returnScopes,
      appId,
      reAuthenticate,
      reRequest,
      redirectUri,
      disableRedirect,
      isMobile,
    } = this.props;

    const params = {
      client_id: appId,
      redirect_uri: redirectUri,
      state: 'facebookdirect',
      return_scopes: returnScopes,
      scope,
      auth_type: null,
    };

    if (reAuthenticate) params.auth_type = 'reauthenticate';
    if (reRequest) params.auth_type = 'rerequest';

    if (isMobile && !disableRedirect) {
      Log.debug('Logging in via manual flow', 'facebook-auth', params);
      window.location.href = `//www.facebook.com/dialog/oauth?${toQueryString(
        params,
      )}`;
    } else {
      Log.debug('Logging in via SDK', 'facebook-auth', params);
      fbClient().login(this.checkLoginState, {
        scope,
        return_scopes: returnScopes,
        auth_type: params.auth_type,
      });
    }
  };

  render() {
    const { component: Component } = this.props;
    // @ts-ignore
    return <Component onClick={this.click} {...this.props.customProps} />;
  }
}

export default FacebookAuth;
